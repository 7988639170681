import { CASES } from "assets/cases/_cases";
import { AboutMeSection, Footer, Hero } from "../../components";
import { MyCases } from "./components";
import { useEffect } from "react";
import { logFirebase } from "firebase.config";

const MainPage = () => {
    useEffect(() => {
        logFirebase("page_view", {
            firebase_screen: "my_cases_screen",
        })
    }, [])

    return <>
        <Hero />
        <AboutMeSection />
        <MyCases cases={CASES} />
        <Footer />
    </>
}
export default MainPage;