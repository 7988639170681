import styles from "./MyCompanyItem.module.scss";
import { BackgroundBlur, Container, Flex, ParticlesComponent, Typography } from "app/components";
import clsx from "clsx";
import { ImgProp } from "unilts/generic.model";
import { useIsLarge, useIsMedium } from "unilts/media";

type ResponsibilityProp = {
    title: string,
    description: string,
}

export type CompanyProp = {
    id: string,
    logo: ImgProp,
    name: string,
    period: string,
    description: string,
    role: string,
    responsobility: ResponsibilityProp[],
}

type isOdd = {
    isOdd: boolean;
};

const MyCompanyItem: React.FC<CompanyProp & isOdd> = ({ isOdd, ...props }) => {
    const isLarge = useIsLarge();
    const isMedium = useIsMedium();

    return <div className={clsx(styles.company)}>
        <Container>
            <Flex.Row reverse={!isOdd} className="lg-column-rv">
                <Flex.Column className={clsx(styles.columnLeft)}>
                    <Typography.Title htmlTag="h3" className={clsx(styles.title)}>
                        {`${props.name} `}
                        <span>{`(${props.period})`}</span>
                    </Typography.Title>
                    <Typography.Text weight={300} className="mt-12">{props.description}</Typography.Text>
                    <Typography.Text weight={600} className="mt-12">
                        My role:
                        <span> {props.role}</span>
                    </Typography.Text>
                    <Typography.Text weight={600} className="mt-24">My responsobility:</Typography.Text>
                    <ul>
                        {props.responsobility.map((value, ind) =>
                            <li key={`${props.id}_li_${ind}`} className="mt-12">
                                <Typography.Text size="small">
                                    <span>{value.title}: </span>
                                    {value.description}
                                </Typography.Text>
                            </li>
                        )}
                    </ul>
                </Flex.Column>
                <Flex.Column className={clsx(styles.columnRight)}>
                    <div className={clsx(styles.companyLogoWrapper)}>
                        <div className={clsx(styles.companyLogo)}>
                            <BackgroundBlur type="company" left="50%" top="50%" zIndex={-2}
                                opacity={0.45} color={isOdd ? "blue-gradient" : "orange-gradient"}
                                width={isMedium ? "45vw" : undefined} />
                            <img src={props.logo.url} srcSet={`${props.logo.url} 1x, ${props.logo.url2x} 2x`} alt={`logo_${props.id}`} />
                        </div>
                    </div>
                </Flex.Column>
            </Flex.Row>

            {isOdd
                ? <ParticlesComponent id={props.id}
                    width={!isLarge ? 35 : 75}
                    height={!isLarge ? 35 : 50}
                    positionX={!isLarge ? 75 : 50}
                    positionY={!isLarge ? 50 : 30}
                    limit={!isLarge ? undefined : 10} />
                : <ParticlesComponent id={props.id}
                    width={!isLarge ? 35 : 75}
                    height={!isLarge ? 60 : 50}
                    positionX={!isLarge ? 25 : 50}
                    positionY={!isLarge ? 50 : 30}
                    limit={!isLarge ? undefined : 10} />
            }
        </Container>
    </div>
}

export default MyCompanyItem;