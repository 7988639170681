export const skills = [
    {
        title: "Technical Skills",
        skills: [
            "Flutter", "Dart", "Provider", "BloC", "JavaScript", "TypeScript", "React", "Angular", "SCSS", "MobX", "Firebase",
            "SDK", "Mobile App Development", "CI/CD", "Multithreading", "Unit & Widget tests", "Python", "FastAPI", "Doker"
        ]
    }, {
        title: "Development Methodologies and Tools",
        skills: [
            "Agile", "Scrum", "Jira", "Linear", "Code Review"
        ]
    }, {
        title: "Soft Skills",
        skills: [
            "Problem-solving", "Decision Making", "Adapting to Challenges", "Teamwork", "Design Thinking", "Communication"
        ]
    }
]