import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/styles.scss'
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import router from 'app/router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.Suspense fallback="...loading">
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </React.Suspense>
);

reportWebVitals();
