import { Navbar } from "app/components";
import { AnimatePresence, MotionConfig } from "framer-motion";
import React from "react";
import { Outlet, useLocation, useOutlet } from "react-router-dom";
import ScrollToAnchor from "unilts/anchor";

const MainLayout = () => {
    const loc = useLocation();
    const element = useOutlet();

    return <>
        <Navbar />
        <ScrollToAnchor />
        <Outlet />
        {/* <MotionConfig reducedMotion="user">
            <AnimatePresence mode="wait" initial={true} onExitComplete={() => ScrollToAnchor(loc, element)}>
                {element && React.cloneElement(element, { key: loc.pathname })}
            </AnimatePresence>
        </MotionConfig> */}
    </>
}

export default MainLayout;