import styles from './FlexComponent.module.scss';
import clsx from "clsx";

type FlexComponentProps = React.PropsWithChildren<{
    className?: string,
}>

const Wrapper: React.FC<FlexComponentProps> = ({ className, children }) => {
    return <div className={clsx(className, styles.wrap)}>{children}</div>
}

type ColumnProps = {
    width?: string,
    minWidth?: string,
    maxWidth?: string,
} & FlexComponentProps;
const Column: React.FC<ColumnProps> = ({ className, children, width, minWidth, maxWidth }) => {
    return <div
        className={clsx(className, styles.column)}
        style={{ width: minWidth ?? width, minWidth: minWidth, maxWidth: maxWidth }}>
        {children}
    </div>
}

type RowProps = {
    columns?: 2 | 3 | 4,
    hasPaddings?: boolean,
    verticalAxis?: "top" | "center" | "bottom",
    reverse?: boolean,
}

const Row: React.FC<FlexComponentProps & RowProps> = ({
    reverse = false, hasPaddings = true, verticalAxis = "center", ...props
}) => {
    return <div className={clsx(styles.row, {
        [styles[`column-${props.columns}`]]: props.columns,
        [styles.hasPaddings]: hasPaddings,
        [styles[verticalAxis]]: verticalAxis,
        [styles.reverse]: reverse,
    }, props.className)}>{props.children}</div>
}

const Space = () => {
    return <div className={styles.space} />
}

export const Flex = { Wrapper, Column, Row, Space };