export const mainVariants = {
    clanqAppVariant: {
        offscreen: {
            opacity: 0,
            y: 200,
        },
        onscreen: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                bounce: 0.2,
                duration: 1,
                delay: 0.6
            }
        }
    },
    clanqCardVariant: {
        offscreen: {
            opacity: 0,
            rotate: 0,
            x: "-50%",
        },
        onscreen: {
            x: "-22%",
            rotate: 13,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.8,
                delay: 0.85,
            }
        }
    },
    singleVariant: {
        offscreen: {
            opacity: 0,
            x: 200,
        },
        onscreen: {
            opacity: 1,
            x: 15,
            transition: {
                type: "spring",
                bounce: 0.3,
                duration: 1,
                delay: 0.6
            }
        }
    },
    multiVariant1: {
        offscreen: {
            opacity: 0,
            y: -200,
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.3,
                duration: 1,
                delay: 0.6
            }
        }
    },
    multiVariant2: {
        offscreen: {
            opacity: 0,
            y: 250,
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.3,
                duration: 1,
                delay: 0.6
            }
        }
    },
}

export const moreCaseMotion = {
    clanqVariant: (delay: number) => (
        {
            onscreen: {
                y: 0,
                scale: 1,
                opacity: 1,
                transition: {
                    type: "spring",
                    bounce: 0.4,
                    duration: 1,
                    delay,
                }
            }
        }
    )
}
