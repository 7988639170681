import { Container, Title, Typography } from "app/components";
import styles from "./MySkills.module.scss";
import { skills } from "../skills";
import MySkillsItem from "../MySkillsItem/MySkillsItem";
import clsx from "clsx";

const MySkills = () => {
    return <Container className={clsx(styles.wrapper)}>
        <Title title="My Skills" supTitle="Skills" position="left" />
        {skills.map((e) => <div key={e.title} className={clsx(styles.title)}>
            <Typography.Title htmlTag="h4">{e.title}</Typography.Title>
            <div className={clsx(styles.skillsRow)}>
                {e.skills.map((el, ind) =>
                    <MySkillsItem key={`${el}_${ind}`} value={el} />)
                }
            </div>
        </div>)}
    </Container>
}

export default MySkills;