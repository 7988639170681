import { AppRoutes } from "app/router";
import { Container, Flex } from "../_layouts";
import LinkButton from "../LinkButton/LinkButton";
import clsx from "clsx";
import styles from "./Navbar.module.scss";
import { useEffect, useRef, useState } from "react";
import { useIsMedium } from "unilts/media";

enum ENavState {
    TOP = "top",
    HIDE = "hide",
    SHOW = "show",
};

const Navbar = () => {
    const navRef = useRef<HTMLDivElement>(null);
    const [navState, setNavState] = useState<ENavState>(ENavState.TOP);
    const mobileLg = useIsMedium();

    var _currentPos: number = 0;
    var _topPosition: number = !mobileLg ? 56 : 0;
    var _fullPosition: number = 0;


    const getNavData = () => {
        _fullPosition = navRef.current?.clientHeight ?? 0;
    }

    const scrollListener = (e: Event) => {
        const top = window.scrollY;

        if (top > _fullPosition && top > _currentPos) {
            setNavState(ENavState.HIDE);
        } else if (top > _fullPosition && top < _currentPos) {
            setNavState(ENavState.SHOW);
        } else if (top <= _topPosition) {
            setNavState(ENavState.TOP);
        }
        _currentPos = top;
    }

    useEffect(() => {
        getNavData();
        window.addEventListener("scroll", scrollListener);
        return () => {
            window.removeEventListener("scroll", scrollListener);
        }
    }, [navRef, mobileLg])

    return <nav ref={navRef} className={clsx(styles.navbar, {
        [styles[navState]]: navState,
    })}>
        <Container>
            <Flex.Row hasPaddings={false}>
                <LinkButton name="My Cases" linkTo={AppRoutes.homePage} />
                <LinkButton name="About me" linkTo={AppRoutes.aboutPage} />
                <Flex.Space />
                <LinkButton icon="icon-linkedin" className={clsx(styles.navIcon)} href="https://www.linkedin.com/in/ruslan-hreshyshchev" />
                <LinkButton icon="icon-mail" href="mailto:ruslan@hreshyshchev.me?subject=From CV Landing Page" />
                {/* <LinkButton name="Download" icon="icon-download" onClick={() => { }} /> */}
            </Flex.Row>
        </Container>
    </nav>
}

export default Navbar;