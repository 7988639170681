import React from "react";
import clsx from "clsx";
import style from "./Typography.module.scss";

// Titles
// h1 = 100, h2 = 80, h3 = 40
type TitleProps = React.PropsWithChildren<{
    htmlTag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
    className?: string,
}>;

const Title: React.FC<TitleProps> = ({ htmlTag, className, children }) => {
    return React.createElement(
        htmlTag, { className }, children
    );
}

// Subtitles
type TextProps = React.PropsWithChildren<{
    className?: string,
    size?: 'large' | 'medium' | 'small',
    weight?: number,
}>

const Text: React.FC<TextProps> = ({ className, size = 'medium', weight, children }) => {
    return <p
        className={clsx(className, style[`text_${size}`])}
        style={{ fontWeight: weight }}
    >{children}</p>
}



export const Typography = { Title, Text };