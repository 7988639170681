import clsx from "clsx";
import { MyCaseCardImgProp } from "./MyCaseCardMotion";
import { m } from "framer-motion";
import styles from "./MyCaseCardMotion.module.scss";
import { mainVariants } from "../MotionVariants";
import { useIsLarge } from "unilts/media";

const SingleType: React.FC<MyCaseCardImgProp> = ({ imgUrl }) => {
    const { singleVariant } = mainVariants;
    const mainImg = imgUrl[0];
    const tablet = useIsLarge();

    return <div className={clsx(styles.mainCaseGroup, { [styles.mainCaseGroupMod]: tablet })}>
        <m.div className={clsx(styles.mainCaseGroupSingle)} variants={singleVariant}
            style={{ y: !tablet ? "-50%" : "0" }}>
            <img src={mainImg.url} srcSet={`${mainImg.url} 1x, ${mainImg.url2x} 2x`} alt="clanq" />
        </m.div>
    </div>
}

const MultiType: React.FC<MyCaseCardImgProp> = ({ imgUrl }) => {
    const { multiVariant1, multiVariant2 } = mainVariants;
    const mainImg1 = imgUrl[0];
    const mainImg2 = imgUrl[1];

    const tablet = useIsLarge();

    return <div className={clsx(styles.mainCaseGroup)}>
        <m.div className={clsx(styles.mainCaseGroupMulti)}
            variants={multiVariant1}
            style={!tablet ? { x: "calc(100% - 40px)" } : { x: "calc(50% - 25px)" }}
        >
            <img src={mainImg2.url} srcSet={`${mainImg2.url} 1x, ${mainImg2.url2x} 2x`} alt="clanq" />
        </m.div>
        <m.div className={clsx(styles.mainCaseGroupMulti)}
            variants={multiVariant2}
            style={!tablet ? { x: 10 } : { x: "calc(-100% + 25px)" }}
        >
            <img src={mainImg1.url} srcSet={`${mainImg1.url} 1x, ${mainImg1.url2x} 2x`} alt="clanq" />
        </m.div>
    </div>
}

const ClanqType: React.FC<MyCaseCardImgProp> = ({ imgUrl }) => {
    const { clanqAppVariant, clanqCardVariant } = mainVariants;
    const mainImg = imgUrl[0];
    const cardImg = imgUrl[1];
    const tablet = useIsLarge();

    return <div className={clsx(styles.mainCaseGroup)}>
        <m.div style={{ x: !tablet ? "25%" : "-25%" }} className={clsx(styles.mainCaseGroupApp)} variants={clanqAppVariant}>
            {/* <div className={clsx(styles.mainCaseGroupApp)}> */}
            <img src={mainImg.url} srcSet={`${mainImg.url} 1x, ${mainImg.url2x} 2x`} alt="clanq" />

            <m.div className={clsx(styles.mainCaseGroupCard)} variants={clanqCardVariant} >
                <img src={cardImg.url} srcSet={`${cardImg.url} 1x, ${cardImg.url2x} 2x`} alt="clanq-card" />
            </m.div>
        </m.div>
    </div>
}

export const CaseMotion = { ClanqType, SingleType, MultiType }