import clsx from "clsx";
import styles from "./Footer.module.scss";
import { BackgroundBlur, Container, ParticlesComponent } from "../_layouts";
import FooterForm from "./FooterForm";
import CasesOverview from "./components/CasesOverview/CasesOverview";
import { useLocation } from "react-router-dom";
import { AppRoutes } from "app/router";
import { useIsLarge } from "unilts/media";
import { useEffect, useState } from "react";
import { OpacityMotion } from "unilts/variants";

const Footer = () => {

    const location = useLocation();
    const isTablet = useIsLarge();
    const [showCases, setShowCases] = useState(false);

    useEffect(() => {
        setShowCases(AppRoutes.aboutPage === location.pathname);
    }, [])

    return <footer>
        <div className={clsx(styles.footerWrapper)}>
            {showCases && <CasesOverview />}

            {/* <OpacityMotion> */}
            <ParticlesComponent id="footer_partcls" width={100} height={100} positionX={50} positionY={50} limit={isTablet ? 10 : undefined} />
            {/* </OpacityMotion> */}
            <Container className={clsx(styles.footerInner)}>
                <BackgroundBlur width={isTablet ? "50vw" : 500} top="45%" left="50%" type="page" opacity={0.14} />
                {/* <OpacityMotion delay={0.4} y={-50} scale={1}> */}
                <div className={clsx(styles.footerTextTop)}>Thank you</div>
                {/* </OpacityMotion> */}
                {/* <OpacityMotion delay={0.6} y={50} scale={1}> */}
                <div className={clsx(styles.footerTextMiddle)}>For</div>
                {/* </OpacityMotion> */}
                {/* <OpacityMotion delay={0.8} y={50} scale={1}> */}
                <div className={clsx(styles.footerTextBottom)}>Viewing</div>
                {/* </OpacityMotion> */}
                {/* <FooterForm /> */}
            </Container>
        </div >
        <div className={clsx(styles.footerLine)}>From Ukraine. 🇺🇦</div>
    </footer>

}

export default Footer;  