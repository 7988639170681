import { ABOUT_US } from "assets/images/_images";
import styles from "./Avatar.module.scss";
import clsx from "clsx";

const Avatar = () => {
    return <div className={clsx(styles.avatar)}>
        <img src={ABOUT_US.url} srcSet={`${ABOUT_US.url} 1x, ${ABOUT_US.url2} 2x`} alt="avatar" />
    </div>
}

export default Avatar;