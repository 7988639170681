import clsx from "clsx";
import styles from "./CasesOverview.module.scss";
import { Button, Container, Typography } from "app/components";
import { CASES_OVERVIEW } from "assets/cases/_cases";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "app/router";
import { logFirebase } from "firebase.config";


const CasesOverview = () => {

    const navigate = useNavigate();

    const toAboutPage = (_: any) => {
        logFirebase("to_my_cases_btn")
        navigate(AppRoutes.homePage + "#my_cases");
    }

    return <Container>
        <div className={clsx(styles.overview)}>
            <div className={clsx(styles.overviewTitle)}>
                <Typography.Title htmlTag="h3">My Cases</Typography.Title>
                <Typography.Text size="small">Don't forget to take a look at my works.</Typography.Text>
            </div>
            <div className={clsx(styles.overviewBottom)}>
                {CASES_OVERVIEW.map((img, ind) =>
                    <img key={img.id} src={img.url} srcSet={`${img.url} 1x, ${img.url2x} 2x`} alt={`${img.id}_image`} />
                )}
            </div>
            <Button
                icon="icon-arrow_right_alt"
                onClick={toAboutPage}
            >See my cases</Button>
        </div>
    </Container>
}

export default CasesOverview;