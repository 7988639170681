import { BackgroundBlur, Flex, ParticlesComponent, Typography } from "app/components";
import styles from "./MyCaseCard.module.scss";
import clsx from "clsx";
import { ReactElement } from "react";
import { MyCaseCardMotionProp } from "../MyCaseCardMotion/MyCaseCardMotion";
import { useIsLarge } from "unilts/media";
import { OpacityMotion } from "unilts/variants";
import { LazyMotion, domAnimation } from "framer-motion";
import { logFirebase } from "firebase.config";

export type MyCaseCardProp = {
    id: string,
    title: string,
    tasks: string[],
}

type MyCaseMotionProp = {
    children: ReactElement<MyCaseCardMotionProp>,
    isOdd: boolean,
    noShadow: boolean,
}

const MyCaseCard: React.FC<MyCaseCardProp & MyCaseMotionProp> = (props) => {

    const isTablet = useIsLarge();

    const animationComplete = () => {
        logFirebase(`case_reached_${props.id}`)
    }

    return <LazyMotion features={domAnimation}>
        <div className={clsx(styles.card, {
            [styles.cardIsOdd]: props.isOdd,
            [styles.cardNoShadow]: props.noShadow,
        })}
        // initial="offscreen"
        // whileInView="onscreen"
        // viewport={{ once: true, amount: 0.8 }}
        >
            <Flex.Row hasPaddings={false} verticalAxis="bottom" className="lg-column-rv">
                <Flex.Column className={clsx(styles.cardLeft, styles.half)} width="100%">
                    <Typography.Title htmlTag="h4">{props.title}</Typography.Title>
                    <p className={clsx(styles.cardLeftSubtitle)}>My Tasks:</p>
                    <ul className={clsx(styles.cardLeftList)}>
                        {props.tasks.map((el, ind) =>
                            <li key={`${props.id}_li_${ind}`}>
                                {el}
                            </li>
                        )}
                    </ul>
                </Flex.Column>
                <Flex.Column width="100%">
                    <OpacityMotion onAnimationComplete={animationComplete}>
                        <div className={clsx(styles.cardRight)}>
                            <BackgroundBlur width={250} left="85%" opacity={0.25} top="20px" type="caseCard" color="orange" />
                            <BackgroundBlur width={350} height={280} left="45%" top="60%" type="caseCard" color="blue-gradient-case" />
                            {props.children}
                        </div>
                    </OpacityMotion>
                </Flex.Column>
            </Flex.Row>
            <ParticlesComponent id={props.id}
                width={isTablet ? 100 : undefined}
                height={isTablet ? 70 : undefined}
                positionX={isTablet ? 50 : undefined}
                positionY={isTablet ? 90 : undefined}
                limit={isTablet ? 10 : undefined}
            />
        </div>
    </LazyMotion>
}

export default MyCaseCard;
