import { ISourceOptions } from "@tsparticles/engine";
import { distance } from "framer-motion";

export interface ICreateParticles {
    width?: number,
    height?: number,
    positionX?: number,
    positionY?: number,
    limit?: number,
}

export const particlesCongf = (args: ICreateParticles): ISourceOptions => {
    return {
        detectRetina: true,
        fullScreen: {
            enable: false,
        },
        fpsLimit: 30,
        pauseOnOutsideViewport: true,
        interactivity: {
            detectsOn: "canvas",
            events: {
                onHover: {
                    enable: true,
                    mode: "attract",
                    parallax: {
                        enable: false,
                        force: 200,
                        smooth: 100,
                    }
                }
            },
            modes: {
                attract: {
                    distance: 200,
                    easing: "ease-in",
                    factor: 0.5,
                    maxSpeed: 10,
                    speed: 1,
                },
            }
        },
        emitters: {
            size: {
                width: args.width ?? 50,
                height: args.height ?? 70,
            },
            position: {
                x: args.positionX ?? 75,
                y: args.positionY ?? 70
            },
            rate: {
                delay: 0.2,
                quantity: 0.5
            },
        },
        particles: {
            number: {
                limit: {
                    mode: "wait",
                    value: args.limit ?? 10
                },
            },
            reduceDuplicates: true,
            move: {
                enable: true,
                random: true,
                speed: { min: 0.05, max: 0.4 },
                direction: "top",
                outModes: "destroy",
                gravity: {
                    acceleration: .3,
                    enable: true,
                    inverse: true,
                    maxSpeed: 0.5,
                },
            },
            color: {
                value: "#FC7133"
            },
            opacity: {
                value: { min: 0, max: 1 },
                animation: {
                    enable: true,
                    destroy: "min",
                    mode: "decrease",
                    speed: 0.8,
                }
            },
            size: {
                value: { min: 0.3, max: 15 },
                animation: {
                    enable: true,
                    speed: 2,
                    startValue: "min",
                    mode: "random",
                    sync: true,
                }
            },
        },
    };
};
