import { BackgroundBlur, Title } from "app/components";
import MyCaseItem, { MyCaseItemProp } from "../MyCaseItem/MyCaseItem"
import styles from "./MyCases.module.scss";
import clsx from "clsx";
import { OpacityMotion } from "unilts/variants";

type MyCasesProps = {
    cases: MyCaseItemProp[];
}
const MyCases: React.FC<MyCasesProps> = ({ cases }) => {
    return <section id="my_cases">
        <BackgroundBlur left="-80px" top="7.2%" type="page" />
        <BackgroundBlur left="70px" top="33%" type="page" zIndex={-1} />
        <BackgroundBlur left="105%" top="60%" type="page" />
        {/* <OpacityMotion> */}
        <Title title="My Cases" supTitle="Cases" />
        {/* </OpacityMotion> */}
        <div className={clsx(styles.titleOffset)}>
            {cases.map((el, i) =>
                <MyCaseItem key={`${el.id}_key`} isOdd={i % 2 !== 0} {...el} />)
            }
        </div>
    </section>
}

export default MyCases;