import angular from "assets/images/angular.webp";
import angular2x from "assets/images/angular@2x.webp";
import react from "assets/images/react.webp";
import react2x from "assets/images/react@2x.webp";
import flutter from "assets/images/flutter.webp";
import flutter2x from "assets/images/flutter@2x.webp";

import ava from "assets/images/rusl.webp";
import ava2x from "assets/images/rusl@2x.webp";

import arrowDown from "assets/images/arrow_down.webp";
import arrowDown2x from "assets/images/arrow_down@2x.webp";

export const HERO_BACKGROUND_IMAGES = [
    {
        id: "hero_bg_angular",
        className: "angular",
        url: angular,
        url2: angular2x
    }, {
        id: "hero_bg_react",
        className: "react",
        url: react,
        url2: react2x
    }, {
        id: "hero_bg_flutter",
        className: "flutter",
        url: flutter,
        url2: flutter2x
    }
];

export const HERO_ARROW_DOWN = {
    url: arrowDown,
    url2: arrowDown2x
}

export const ABOUT_US = {
    id: "about_us_ava",
    className: "avatar",
    url: ava,
    url2: ava2x
};