// Clanq images
import clanqLogo from "assets/cases/clanq/clanq-logo.webp";
import clanqLogo2x from "assets/cases/clanq/clanq-logo@2x.webp";
import clanqMainCase from "assets/cases/clanq/clanq-main-case.webp";
import clanqMainCase2x from "assets/cases/clanq/clanq-main-case@2x.webp";
import clanqMainCaseCard from "assets/cases/clanq/clanq-case-card.webp";
import clanqMainCaseCard2x from "assets/cases/clanq/clanq-case-card@2x.webp";
import clanqCase1 from "assets/cases/clanq/clanq-case-1.webp";
import clanqCase1_2x from "assets/cases/clanq/clanq-case-1@2x.webp";
import clanqCase2 from "assets/cases/clanq/clanq-case-2.webp";
import clanqCase2_2x from "assets/cases/clanq/clanq-case-2@2x.webp";
import clanqCase3 from "assets/cases/clanq/clanq-case-3.webp";
import clanqCase3_2x from "assets/cases/clanq/clanq-case-3@2x.webp";

// MasterMate images
import masterLogo from "assets/cases/mastermate/logo.webp";
import masterLogo2x from "assets/cases/mastermate/logo.webp";
import masterHome from "assets/cases/mastermate/home.webp";
import masterHome2x from "assets/cases/mastermate/home@2x.webp";
import masterCalendar from "assets/cases/mastermate/schedule-calendar.webp";
import masterCalendar2x from "assets/cases/mastermate/schedule-calendar@2x.webp";
import masterAppointments from "assets/cases/mastermate/clients-appointments.webp";
import masterAppointments2x from "assets/cases/mastermate/clients-appointments@2x.webp";
import masterFinances from "assets/cases/mastermate/my_finances.webp";
import masterFinances2x from "assets/cases/mastermate/my_finances@2x.webp";
import masterAddEvent from "assets/cases/mastermate/add_event.webp"
import masterAddEvent2x from "assets/cases/mastermate/add_event@2x.webp";
import masterProfile from "assets/cases/mastermate/clients-profile-appointments.webp";
import masterProfile2x from "assets/cases/mastermate/clients-profile-appointments@2x.webp";

// Global Card images
import globalLogo from "assets/cases/global/global-logo.webp";
import globalLogo2x from "assets/cases/global/global-logo@2x.webp";
import globalMainCase from "assets/cases/global/global-main-case.webp";
import globalMainCase2x from "assets/cases/global/global-main-case@2x.webp";

// Motolino images
import motoLogo from "assets/cases/mototlino/motolino.webp";
import motoLogo2x from "assets/cases/mototlino/motolino@2x.webp";
import motoMainCase from "assets/cases/mototlino/motolino-main-case.webp";
import motoMainCase2x from "assets/cases/mototlino/motolino-main-case@2x.webp";
import motoMainCase2 from "assets/cases/mototlino/motolino-main-case2.webp";
import motoMainCase2_2x from "assets/cases/mototlino/motolino-main-case2@2x.webp";
import motoCase from "assets/cases/mototlino/moto-case.webp";
import motoCase2x from "assets/cases/mototlino/moto-case@2x.webp";

// Dermalogica images
import dermaLogo from "assets/cases/derma/derma-logo.webp";
import dermaLogo2x from "assets/cases/derma/derma-logo@2x.webp";
import dermaMainCase from "assets/cases/derma/derma-main-case.webp";
import dermaMainCase2x from "assets/cases/derma/derma-main-case@2x.webp";
import dermaMainCase2 from "assets/cases/derma/derma-main-case2.webp";
import dermaMainCase2_2x from "assets/cases/derma/derma-main-case2@2x.webp";
import dermaCase from "assets/cases/derma/derma-case.webp";
import dermaCase2x from "assets/cases/derma/derma-case@2x.webp";
import { ImgProp } from "unilts/generic.model";
import { url } from "inspector";
import { title } from "process";

export const CASES = [
    {
        id: "clanq_case",
        logo: {
            url: clanqLogo,
            url2x: clanqLogo2x,
        },
        title: "Clanq: family banking app",
        description: "Clanq is a financial German application designed to assist families in sustainable financial savings for their children's future. The app combines cashback, financial goals, and support from the family clan, providing convenient money management",
        tasks: [
            "Developed from scratch.",
            "Optimized and increased the performance of the app by 20%.",
            "Ensured code quality through analysis, refactoring and advanced software practices.",
            "Identified and resolved performance bottlenecks across devices and configurations.",
            "Safeguarded application and user data privacy through encryption and security measures.",
            "Collaborated with UX/UI designers, QA engineers, and other team members to define requirements and implement features.",
            "Implemented CI/CD for regular releases, cutting wait times and boosting build frequency by 50%.",
            "Created reusable components, improving team productivity by 25%.",
            "Conducted code reviews, resulting in a 15% increase in code quality.",
        ],
        mainCaseImg: [
            {
                url: clanqMainCase,
                url2x: clanqMainCase2x,
            }, {
                url: clanqMainCaseCard,
                url2x: clanqMainCaseCard2x,
            }
        ],
        moreCaseImg: [
            {
                url: clanqCase1,
                url2x: clanqCase1_2x,
            }, {
                url: clanqCase2,
                url2x: clanqCase2_2x,
            }, {
                url: clanqCase3,
                url2x: clanqCase3_2x,
            }
        ],
    },
    {
        id: "master_mate",
        logo: {
            url: masterLogo,
            url2x: masterLogo2x,
        },
        title: "MasterMate: Personal assistant",
        description: "MasterMate is a personal assistant in the beauty industry who helps you plan your day, keep appointments and financial expenses.",
        tasks: [
            "Developed from scratch",
            "Implementing best practices and improving performance",
            "Custom animations creation",
            "Created reusable components, improving team productivity by 30%",
            "Contributed to UI prototyping and technical UI element creation, advising designers and improving UI efficiency by 10%."
        ],
        mainCaseImg: [
            {
                url: masterHome,
                url2x: masterHome2x,
            }, {
                url: masterProfile,
                url2x: masterProfile2x,
            }
        ],
        moreCaseImg: [
            {
                url: masterAppointments,
                url2x: masterAppointments2x,
            },
            {
                url: masterAddEvent,
                url2x: masterAddEvent2x,
            },
            {
                url: masterFinances,
                url2x: masterFinances2x,
            }
        ],
    },
    {
        id: "global_card_case",
        logo: {
            url: globalLogo,
            url2x: globalLogo2x,
        },
        title: "Global Card: online platform",
        description: "Global Card is an independent division of Cornèrcard, providing credit card services to financial intermediaries in 17 countries. This allows other banks, fund managers, and insurance companies to offer credit cards to their customers without the need for their own card processing center. A digital platform has been developed for Global Card to enhance business process management and eliminate manual operations.",
        tasks: [
            "Created a website to manage card issuance in multiple languages.",
            "Collaborated on creating a dashboard for product and offer management.",
            "Improved dynamic PDF creation quality by 40%.",
            "Automated application form generation enables adding new forms 45% faster.",
            "Fixed issues, enhancing UI by 35%.",
        ],
        mainCaseImg: [
            {
                url: globalMainCase,
                url2x: globalMainCase2x,
            }
        ],
    }, {
        id: "motolino_case",
        logo: {
            url: motoLogo,
            url2x: motoLogo2x,
        },
        title: "Motolino: vespa configurator",
        description: "Motolino GmbH, based in Switzerland, focuses exclusively on the beloved Italian Vespa scooter. To facilitate growth, the company required a charismatic web store. Motolino's customers can now configure their personalized Vespa through an attractive live animation feature.",
        tasks: [
            "Development of a web application using React.",
            "Saleor customization.",
            "Code quality control.",
            "Debugging and issue resolution.",
            "Optimized components and fixed issues, enhancing UI by 25%.",
            "Collaborated on developing a customizable scooter configuration with attractive animation.",
        ],
        mainCaseImg: [
            {
                url: motoMainCase,
                url2x: motoMainCase2x,
            }, {
                url: motoMainCase2,
                url2x: motoMainCase2_2x,
            }
        ],
        moreCaseImg: [
            {
                url: motoCase,
                url2x: motoCase2x,
            }
        ],
    }, {
        id: "dermalgica_case",
        logo: {
            url: dermaLogo,
            url2x: dermaLogo2x,
        },
        title: "Dermalogica: Offline webshop",
        description: "Dermalogica, a skincare brand present in 100+ countries, enhanced its competitive edge in Switzerland through a new web store.",
        tasks: [
            "Optimized components and increased loading speed by 20%.",
            "Led the project that allowed for timely task completion and on-schedule project closure.",
            "Implemented cross-browser compatibility solutions, boosting website traffic by 20%.",
            "Development of a web application using React.",
            "Saleor customization.",
            "Debugging and issue resolution.",
        ],
        mainCaseImg: [
            {
                url: dermaMainCase,
                url2x: dermaMainCase2x,
            }, {
                url: dermaMainCase2,
                url2x: dermaMainCase2_2x,
            }
        ],
        moreCaseImg: [
            {
                url: dermaCase,
                url2x: dermaCase2x,
            }
        ],
    }
];

export const CASES_OVERVIEW: ImgProp[] = [
    {
        id: "c_overview_derma",
        url: dermaMainCase,
        url2x: dermaMainCase2x,
    },
    {
        id: "c_overview_moto",
        url: motoMainCase,
        url2x: motoMainCase2x,
    },
    {
        id: "c_overview_clanq",
        url: clanqCase2,
        url2x: clanqCase2_2x,
    }
]