import { m } from "framer-motion"
import { PropsWithChildren } from "react"
import { useIsSmall } from "./media"

export const opacityVariants = {
    animate: {
        opacity: 'var(--opacity-animate)',
        y: 'var(--y-animate-animate)',
        transition: { duration: 0.5 }
    },
    exit: {
        opacity: 'var(--opacity-exit)',
        y: 'var(--y-exit)',
        transition: { duration: 0.5 }
    }
}

type OpacityMotionProp = {
    y?: number,
    duration?: number,
    delay?: number,
    amount?: number,
    className?: string,
    style?: Object,
    scale?: number,
}

export const opacityMotion = (props?: OpacityMotionProp) => {
    return {
        exit: {
            opacity: 0,
            y: props?.y ?? 10,
            transition: { duration: 0.7 }
        },
        offscreen: {
            opacity: 0,
            y: props?.y ?? 10,
            scale: props?.scale ?? 0.95
        },
        onscreen: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                // type: "spring",
                // bounce: props?.scale ? 0.0 : 0.4,
                duration: props?.duration ?? 0.6,
                delay: props?.delay
            },
        },
    }
}

type OpacityCallbackProp = {
    onAnimationComplete?: () => void
} & OpacityMotionProp & PropsWithChildren

export const OpacityMotion: React.FC<OpacityCallbackProp> = ({ children, ...props }) => {
    const conf = opacityMotion(props);
    const isMobile = useIsSmall();

    return (
        <m.div
            onAnimationComplete={props.onAnimationComplete}
            style={props.style}
            className={props.className}
            initial="offscreen"
            whileInView="onscreen"
            variants={conf}
            viewport={{ once: true, amount: props.amount ?? isMobile ? 0.3 : 0.6 }}
        >
            {children}
        </m.div>
    );
}