import clsx from "clsx";
import styles from "./Container.module.scss";
import { forwardRef } from "react";

type ContainerProps = React.HTMLProps<HTMLDivElement> & {
    margin?: string,
};

const Container = forwardRef<HTMLDivElement, ContainerProps>(({ className, margin, children, ...props }, ref) => {
    return <div ref={ref} {...props} className={clsx(className, styles.container)} style={{ margin: margin }}>
        {children}
    </div>
})

export default Container;