import clanqAg from "./clanq-ag-logo.webp";
import clanqAg2x from "./clanq-ag-logo@2x.webp";
import mindnow from "./mindnow-logo.webp";
import mindnow2x from "./mindnow-logo@2x.webp";
import nikitin from "./nikitin-logo.webp";
import nikitin2x from "./nikitin-logo@2x.webp";
import { CompanyProp } from "app/pages/AboutPage/components/MyCompanyItem/MyCompanyItem";

export const COMPANIES: CompanyProp[] = [
    {
        id: "clanq-ag",
        logo: {
            url: clanqAg,
            url2x: clanqAg2x,
        },
        name: "Clanq Agency",
        period: "may 2021 - nov 2023",
        description: "Clanq is a financial startup based in Zurich, founded in 2021. It provides innovative financial solutions for sustainable savings for children's futures.",
        role: "Senior Frontend Developer",
        responsobility: [
            {
                title: "Cross-platform development",
                description: "Developing and optimizing a mobile banking application for both iOS and Android platforms.",
            },
            {
                title: "Code quality",
                description: "Ensuring code quality through regular analysis, refactoring, and the implementation of advanced software practices to maintain a clean and efficient codebase.",
            },
            {
                title: "Performance optimization",
                description: "Identifying and resolving performance bottlenecks to ensure the smooth and efficient operation of the application across a wide range of devices and configurations.",
            },
            {
                title: "Security",
                description: "Safeguarding the application and user data privacy through encryption and other security measures.",
            },
            {
                title: "Collaboration",
                description: "Working closely with ux/ui designers, QA engineers and other engineers to define requirements and implement features.",
            },
            {
                title: "Continuous integration and deployment",
                description: "Implementing and maintaining Continuous Integration / Continuous Deployment(CI / CD) pipelines to ensure regular releases with minimal downtime and high reliability.",
            },
            {
                title: "Debugging and troubleshooting",
                description: "Detecting and resolving complex issues that may arise in the application, ensuring uninterrupted operation.",
            },
            {
                title: "Research and development",
                description: "Actively exploring and implementing cutting - edge technologies and best practices in mobile development, as well as evaluating and recommending new tools, frameworks, and methodologies to enhance application performance and efficiency.",
            },
        ]
    },
    {
        id: "mindow-copmany",
        logo: {
            url: mindnow,
            url2x: mindnow2x,
        },
        name: "Mindnow",
        period: "apr 2019 - may 2021",
        description: "Swiss company specializing in flexible product development, from applications and websites to digital ecosystems.",
        role: "Middle/Senior Frontend developer",
        responsobility: [
            {
                title: "Development",
                description: "Crafting web applications using React and Angular, along with Saleor for online stores."
            },
            {
                title: "Code optimization",
                description: "Refining and applying advanced programming methods to ensure the cleanliness and efficiency of the code."
            },
            {
                title: "Collaboration",
                description: "Engaging closely with UX/UI designers, QA engineers, and fellow developers to discern requirements and integrate new functionalities."
            },
            {
                title: "Debugging and issue resolution",
                description: "Identifying and resolving intricate issues that may arise within the application."
            },
            {
                title: "Research and development",
                description: "Exploring cutting-edge technologies and best practices in web development, as well as suggesting new tools and libraries to elevate application performance and efficiency."
            },
        ]
    },
    {
        id: "nikitin-company",
        logo: {
            url: nikitin,
            url2x: nikitin2x,
        },
        name: "Nikitin",
        period: "sep 2017 - apr 2019",
        description: "Ukrainian company specializing in designing and developing websites and mobile applications.",
        role: "Junior/Middle Frontend developer",
        responsobility: [
            {
                title: "Development",
                description: "Creating applications using Angular, Ionic, Electron, and Wordpress."
            },
            {
                title: "Collaboration",
                description: "Interacting with clients, designers, and other developers to implement new functionalities and resolve issues."
            },
            {
                title: "Research and development",
                description: "Proposing and utilizing new technologies in both desktop and mobile development."
            },
            {
                title: "Consulting",
                description: "Assisting other developers when facing challenges and adjusting actions."
            },
        ]
    },
]