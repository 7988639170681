import { createBrowserRouter } from "react-router-dom";
import { MainPage, AboutPage, NotFound } from "./pages";
import { MainLayout } from "./components";

export enum AppRoutes {
    homePage = "/",
    aboutPage = "/about"
}

const router = createBrowserRouter([
    {
        path: AppRoutes.homePage,
        element: <MainLayout />,
        children: [
            {
                index: true,
                element: <MainPage />,
            },
            {
                path: AppRoutes.aboutPage,
                element: <AboutPage />,
            },
        ]
    },
    {
        path: "*",
        element: <NotFound />
    }
]);

export default router;