// const ScrollToAnchor = (location: any, element: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null) => {
//     var id = location.hash.slice(1);
//     if (location) {
//         setTimeout(() => {
//             document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
//         }, 300);
//     }
// }

import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToAnchor = () => {
    const location = useLocation();
    const lastHash = useRef("")

    useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1)
        }

        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                const yOffset = -65;
                const element: HTMLElement | null = document.getElementById(lastHash.current);
                if (element != null) {
                    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

                    window.scrollTo({ top: y, behavior: 'smooth' });
                    lastHash.current = "";
                }
            }, 100);
        }
    }, [location, lastHash])

    return null;
}

export default ScrollToAnchor;