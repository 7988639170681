import clsx from "clsx";
import styles from "./MySkillsItem.module.scss";

type MySkillsItemProp = {
    value: string
}

const MySkillsItem: React.FC<MySkillsItemProp> = ({ value }) => {
    return <div className={clsx(styles.skillItemWrapper)}>
        <div className={clsx(styles.skillItem)}>{value}</div>
    </div>
}

export default MySkillsItem;