import clsx from "clsx";
import Avatar from "../Avatar/Avatar";
import Button from "../Button/Button";
import Title from "../Title/Title";
import { Typography } from "../Typography/Typography";
import { Container, Flex } from "../_layouts";
import styles from "./AboutMeSection.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "app/router";
import { useEffect, useState } from "react";
import { OpacityMotion } from "unilts/variants";
import { logFirebase } from "firebase.config";

const AboutMeSection = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [fulPage, setState] = useState(false);

    const toAboutPage = (_: any) => {
        logFirebase("to_about_me_btn");
        navigate(AppRoutes.aboutPage + "#about_me");
    }

    useEffect(() => {
        setState(location.pathname === AppRoutes.aboutPage);
    }, [])

    return <Container id="about_me" className={clsx(styles.paddings)}>
        <Flex.Row verticalAxis="top" className="lg-column-rv">
            <Flex.Column className={clsx(styles.columnLeft)}>
                <Title title="Ruslan Hreshyshchev" supTitle="About me" position="left" />
                <Typography.Text size="large" className="mt-20">
                    Senior Frontend Developer <span>&#8226;</span> Odessa, Ukraine
                </Typography.Text>
                <Typography.Text className="mt-20">
                    {
                        fulPage
                            ? `With over 7 years in the development industry, I have been directly involved in projects, developing, 
                            delivering and maintaining large scale projects. As a software engineer, I am keen to use my skills 
                            and invest in the team to increase productivity and efficiency.`
                            : `With over 7 years in the development industry, I have been...`
                    }

                </Typography.Text>
                {!fulPage && <Button
                    className="mt-40"
                    onClick={toAboutPage}
                    icon="icon-arrow_right_alt">More About me</Button>
                }
            </Flex.Column>
            <Flex.Space />
            <Flex.Column className={clsx("mt-50", styles.columnRight)}>
                <Avatar />
            </Flex.Column>
        </Flex.Row>
    </Container>
}

export default AboutMeSection;

function useLog() {
    throw new Error("Function not implemented.");
}
