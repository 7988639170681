import { Container, Flex, Typography } from "app/components"
import MyCaseCard, { MyCaseCardProp } from "../MyCaseCard/MyCaseCard"
import styles from "./MyCaseItem.module.scss";
import clsx from "clsx";
import MyCaseCardMotion from "../MyCaseCardMotion/MyCaseCardMotion";
import MyCaseMotion from "../MyCaseMotion/MyCaseMotion";
import { ImgProp } from "unilts/generic.model";
import { useIsMedium } from "unilts/media";
import { OpacityMotion } from "unilts/variants";

type MyCaseOdd = {
    isOdd: boolean,
}

export type MyCaseItemProp = {
    logo: ImgProp,
    description: string,
    mainCaseImg: ImgProp[],
    moreCaseImg?: ImgProp[],
} & MyCaseCardProp

const MyCaseItem: React.FC<MyCaseItemProp & MyCaseOdd> = (props) => {

    const isMedium = useIsMedium();

    return <div className={clsx(styles.wrapper)}>
        <Container>
            <Flex.Row verticalAxis="top" className="md-column">
                <Flex.Column className={clsx(styles.columnLeft)}>
                    <img src={props.logo.url}
                        srcSet={`${props.logo.url} 1x, ${props.logo.url2x} 2x`}
                        alt={`${props.id}_img`} />
                </Flex.Column>

                <Flex.Column >
                    <Typography.Text size="medium">{props.description}</Typography.Text>
                </Flex.Column>
            </Flex.Row>
            <MyCaseCard
                isOdd={props.isOdd}
                noShadow={props.mainCaseImg.length <= 1}
                id={props.id}
                title={props.title}
                tasks={props.tasks}>
                <MyCaseCardMotion imgUrl={props.mainCaseImg} id={props.id} />
            </MyCaseCard>
            {!isMedium && props.moreCaseImg && <MyCaseMotion id={props.id} caseImg={props.moreCaseImg} />}
        </Container>
    </div>
}

export default MyCaseItem;