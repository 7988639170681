import { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";
import clsx from "clsx";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    className?: string;
    icon?: string;
    loading?: boolean;
    hasLoading?: boolean;
};

const Button: React.FC<ButtonProps> = ({ loading = false, hasLoading = false, className, children, icon, ...props }) => {
    return <button {...props} className={clsx(className, styles.button, {
        [styles.loading]: loading && hasLoading
    })}>
        <p>{children}</p>
        {icon && <span className={clsx(icon)} />}
        {hasLoading &&
            <div className={clsx(styles.loaders)}>
                <div />
                <div />
                <div />
            </div>
        }
    </button>
}

export default Button;