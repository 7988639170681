import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import { ICreateParticles, particlesCongf } from "./config";
import clsx from "clsx";
import styles from "./Particles.module.scss";
import { ISourceOptions } from "@tsparticles/engine";

type ParticlesProps = {
    id: string,
} & ICreateParticles;

const ParticlesComponent: React.FC<ParticlesProps> = ({ id, ...props }) => {
    const [init, setInit] = useState(false);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const options: ISourceOptions = useMemo(
        () => particlesCongf(props), [props],
    );

    if (init) return <Particles
        id={`${id}_particles`}
        options={options}
        className={clsx(styles.particles)}
    />;

    return <></>
}

export default ParticlesComponent;