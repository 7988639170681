import styles from "./MyCompanyList.module.scss";
import MyCompanyItem, { CompanyProp } from "../MyCompanyItem/MyCompanyItem";

type CompanyListProp = {
    data: CompanyProp[];
}

const MyCompanyList: React.FC<CompanyListProp> = ({ data }) => {
    return <section>
        {data.map((value, index) =>
            <MyCompanyItem key={value.id} {...value} isOdd={index % 2 == 0} />
        )}
    </section>;
}

export default MyCompanyList; 