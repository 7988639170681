import { BackgroundBlur, Button, Container } from "app/components";
import styles from "./NotFound.module.scss";
import { useIsMedium } from "unilts/media";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import TextScramble from "app/components/Hero/animation";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "app/router";
import { logFirebase } from "firebase.config";

const NotFound = () => {

    const phrases = ['Oops', '404']

    const isTablet = useIsMedium();
    const ref = useRef(null);
    const navigate = useNavigate();


    useEffect(() => {
        logFirebase("page_view", {
            firebase_screen: "not_found",
        }
        );
    }, [])

    useEffect(() => {
        if (ref.current != null) {
            const fx = new TextScramble(ref.current)

            let counter = 0
            const next = () => {
                fx.setText(phrases[counter]).then(() => {
                    setTimeout(next, 3000);
                })
                counter = (counter + 1) % phrases.length;
            }

            setTimeout(next, 3000);
        }
    }, [ref])

    const goHome = () => {
        logFirebase("go_to_home");
        navigate(AppRoutes.homePage);
    }

    return <section>
        <BackgroundBlur width={isTablet ? "45vw" : undefined} left="120px" top="150px" type="page" />
        <BackgroundBlur left="95%" width={isTablet ? "48vw" : undefined} top="800px" type="page" />

        <Container className={clsx(styles.errorWrapper)}>
            <div className={clsx(styles.errorTextMiddle, styles.animated)} ref={ref}>{phrases[1]}</div>
            <div className={clsx(styles.errorTextBottom, styles.animated)}>Page Not Found</div>
            <Button onClick={goHome}>Go to homepage</Button>
        </Container>
    </section>
}

export default NotFound;