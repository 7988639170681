import clsx from "clsx";
import { MyCaseMotionProps } from "./MyCaseMotion";
import style from "./MyCaseMotion.module.scss";
import { m, motion } from "framer-motion";
import { moreCaseMotion } from "../MotionVariants";

const ClanqType: React.FC<MyCaseMotionProps> = ({ id, caseImg }) => {
    const initials = [100, -50, 80];
    const delay = [0, 0.2, 0.4];
    return <div className={clsx(style.caseGroupClanq)}>
        {
            caseImg!.map((img, ind) =>
                // <motion.div
                //     key={`${id}_img_${ind}`}
                //     variants={moreCaseMotion.clanqVariant(delay[ind])}
                //     initial={{ y: initials[ind], opacity: 0, scale: 0.95 }}>
                //     <img src={img.url} srcSet={`${img.url} 1x, ${img.url2x} 2x`} alt={`${id}${ind}_img`} />
                // </motion.div>
                <div key={`${id}_img_${ind}`}>
                    <img src={img.url} srcSet={`${img.url} 1x, ${img.url2x} 2x`} alt={`${id}${ind}_img`} />
                </div>
            )
        }
    </div >
}

const SingleType: React.FC<MyCaseMotionProps> = ({ id, caseImg }) => {
    const img = caseImg![0];
    return <img
        // variants={moreCaseMotion.clanqVariant(0)}
        // initial={{ y: 50, opacity: 0, scale: 0.95 }}
        src={img.url} srcSet={`${img.url} 1x, ${img.url2x} 2x`} alt={`${id}_case_img`} />
}

export const CaseMotion = { ClanqType, SingleType };