import clsx from "clsx";
import styles from "./LinkButton.module.scss";
import { Link, NavLink } from "react-router-dom";
import { AppRoutes } from "app/router";
import { logFirebase } from "firebase.config";

type LinkButtonProps = {
    name?: string,
    icon?: string,
    className?: string,
    linkTo?: AppRoutes,
    href?: string
}

const LinkButton: React.FC<LinkButtonProps> = ({ name, icon, className, linkTo, href }) => {

    const firebaseLog = () => {
        if (href) {
            logFirebase("social_event", { name: icon == "icon-linkedin" ? "to_linkedin" : "to_gmail" })
        } else {
            logFirebase("navigation", { name: linkTo == AppRoutes.aboutPage ? "to_about_me" : "to_cases" });
        }
    }

    if (linkTo) {
        return <NavLink to={linkTo}
            id={linkTo}
            className={clsx(className, styles.wrapper, styles.navLink)}
            onClick={(_) => firebaseLog()}>
            {name}
        </NavLink>
    }

    if (href) {
        return <Link
            className={clsx(className, { [styles.wrapper]: !!name, [styles.iconWrapper]: !name })}
            to={href} target="_blank"
            onClick={(_) => firebaseLog()}>
            {icon && <span className={clsx(icon, styles.icon)} />}
            {name}
        </Link>
    }

    return <></>
}

export default LinkButton;