import clsx from "clsx";
import { BackgroundBlur, Container } from "../_layouts";
import styles from "./Hero.module.scss";
import { useEffect, useRef } from "react";
import TextScramble from "./animation";

import { HERO_ARROW_DOWN } from "assets/images/_images";
import { useIsMedium } from "unilts/media";
import { useLocation, useNavigate } from "react-router-dom";
import Background from "./components/Background";
import React from "react";
import ScrollToAnchor from "unilts/anchor";

const Hero = () => {
    const phrases = [
        'mobile',
        'web',
    ]

    const ref = useRef(null);
    const isTablet = useIsMedium();
    const location = useLocation();
    const navigator = useNavigate();

    useEffect(() => {
        if (ref.current != null) {
            const fx = new TextScramble(ref.current)

            let counter = 0
            const next = () => {
                fx.setText(phrases[counter]).then(() => {
                    setTimeout(next, 3000);
                })
                counter = (counter + 1) % phrases.length;
            }

            setTimeout(next, 1000);
        }
    }, [ref])

    const arrowDownEvent = (_: React.MouseEvent) => {
        navigator(location.pathname + '#about_me');
    }

    return <section>
        <BackgroundBlur width={isTablet ? "45vw" : undefined} left="120px" top="150px" type="page" />
        <BackgroundBlur left="95%" width={isTablet ? "48vw" : undefined} top="800px" type="page" />

        <Container className={clsx(styles.heroWrapper)}>
            {/* <OpacityMotion className={clsx(styles.heroTextTop, styles.animated)} delay={0.4} y={-50} scale={1}>Hello! I'm</OpacityMotion>
            <OpacityMotion delay={0.8} y={50} scale={1}>
                <div className={clsx(styles.heroTextMiddle, styles.animated)} ref={ref}>mobile</div>
            </OpacityMotion>
            <OpacityMotion delay={1.4} y={50} scale={1} onAnimationComplete={onAnimationComplete}>
                <div className={clsx(styles.heroTextBottom, styles.animated)}>Developer</div>
            </OpacityMotion> */}
            <div className={clsx(styles.heroTextTop, styles.animated)}>Hello! I'm</div>
            <div className={clsx(styles.heroTextMiddle, styles.animated)} ref={ref}>web</div>
            <div className={clsx(styles.heroTextBottom, styles.animated)}>Developer</div>
            <div onClick={arrowDownEvent} className={clsx(styles.arrowDown)} >
                <img
                    src={HERO_ARROW_DOWN.url}
                    srcSet={`${HERO_ARROW_DOWN.url2} 2x, ${HERO_ARROW_DOWN.url} 1x`}
                    alt="arrow_down"
                />
            </div>
            <Background />
        </Container>
    </section>
}

export default Hero;