import { ImgProp } from "unilts/generic.model";
import { CaseMotion } from "./CaseTypes";

export type MyCaseCardImgProp = {
    imgUrl: ImgProp[];
}

export type MyCaseCardMotionProp = MyCaseCardImgProp & {
    id: string,
}

const MyCaseCardMotion: React.FC<MyCaseCardMotionProp> = ({ id, ...props }) => {
    if (id == 'clanq_case') {
        return <CaseMotion.ClanqType {...props} />
    }
    if (props.imgUrl.length == 2) {
        return <CaseMotion.MultiType {...props} />
    }

    return <CaseMotion.SingleType {...props} />
}

export default MyCaseCardMotion;