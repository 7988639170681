import { AboutMeSection, Footer, Hero } from "app/components";
import { MySkills } from "./components";
import MyCompanyList from "./components/MyCompanyList/MyCompanyList";
import { COMPANIES } from "assets/logos/_logo";
import { useEffect } from "react";
import { logFirebase } from "firebase.config";

const AboutPage = () => {
    useEffect(() => {
        logFirebase("page_view", {
            firebase_screen: "about_me_screen",
        })
    }, [])

    return <div>
        <Hero />
        <AboutMeSection />
        <MySkills />
        <MyCompanyList data={COMPANIES} />
        <Footer />
    </div>
}

export default AboutPage;