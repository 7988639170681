import clsx from "clsx";
import styles from "./BackgroundBlur.module.scss";


type BackgroundBlurProps = {
    type: "page" | "caseCard" | "company";
    color?: "blue" | "blue-gradient" | "blue-gradient-case" | "orange" | "orange-gradient";
    width?: string | number;
    height?: string | number;
    left?: string;
    top?: string;
    opacity?: number;
    zIndex?: number;
}

const BackgroundBlur: React.FC<BackgroundBlurProps> = ({ color = "orange", width = 347, ...props }) => {
    return <div
        className={clsx(styles.circle, {
            [styles[props.type]]: props.type,
            [styles[color]]: color,
        })}
        style={{
            width: width,
            height: props.height ?? width,
            left: props.left,
            top: props.top,
            opacity: props.opacity,
            zIndex: props.zIndex,
        }}
    />
}

export default BackgroundBlur;