import clsx from "clsx";
import { Typography } from "../Typography/Typography";
import styles from "./Title.module.scss";

type TitleProps = {
    title: string;
    supTitle: string,
    position?: "center" | "left"
}
const Title: React.FC<TitleProps> = ({ title, supTitle, position = "center" }) => {

    return <div className={clsx(styles.title, styles[position])}>
        <span className={clsx(styles.supTitle)}>
            {supTitle}
        </span>
        <div>
            <Typography.Title htmlTag="h2">{title}</Typography.Title>
        </div>
    </div >
}

export default Title;