import { OpacityMotion } from "unilts/variants";
import { CaseMotion } from "./CaseType";
import { ImgProp } from "unilts/generic.model";
import { LazyMotion, domAnimation, motion } from "framer-motion";

export type MyCaseMotionProps = {
    id: string,
    caseImg?: ImgProp[],
}
const MyCaseMotion: React.FC<MyCaseMotionProps> = (props) => {
    return <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: "all", margin: "0px" }}
    >
        {props.caseImg!.length > 2 ? <CaseMotion.ClanqType {...props} /> : <CaseMotion.SingleType {...props} />}
    </motion.div>
}

export default MyCaseMotion; 