import { HERO_BACKGROUND_IMAGES } from "assets/images/_images";
import styles from "./Background.module.scss";
import clsx from "clsx";
import { m } from "framer-motion";
import { opacityMotion } from "unilts/variants";

const Background = () => {
    return <>
        {HERO_BACKGROUND_IMAGES && HERO_BACKGROUND_IMAGES.map((el, ind) =>
            <div className={clsx(styles[el.className])} key={el.id}>
                <m.img
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                    variants={opacityMotion({ delay: 2.4 + ind / 2, duration: 2 })}
                    src={el.url} srcSet={`${el.url2} 2x, ${el.url} 1x`} />
            </div>
        )}
    </>
}

export default Background; 