import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";
// import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

export const token = "6LckovQpAAAAAIO4KJ5Whl92zP4E9L7nP_6L89NL"

const firebaseConfig = {
    apiKey: "AIzaSyDCYpvB8T9gWODEH1MRoZBPRGcUmgaR9kE",
    authDomain: "ruslancv-a14cf.firebaseapp.com",
    projectId: "ruslancv-a14cf",
    storageBucket: "ruslancv-a14cf.appspot.com",
    messagingSenderId: "950854954642",
    appId: "1:950854954642:web:7db31fc83d2eb33486804b",
    measurementId: "G-KJRDF3GCB7"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaEnterpriseProvider(token),
//     isTokenAutoRefreshEnabled: true
// });

const URL = "hreshyshchev.me";

export const logFirebase = (key: string, options?: { [key: string]: any; }) => {
    if (window.location.host.includes(URL)) {
        logEvent(analytics, key, options);
    } else {
        console.log("LOG EVENT IS OFF")
    }
}
